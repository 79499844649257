body {
	/* Setup Page Layout */
	height: 100%;
	width: 100%;
	max-width: 1920px;
	min-width: 320px;
	margin: auto;

	background-color: #250140;

	overflow: auto;
	font-family: "Droid Sans", sans-serif;
	font-size: 12px;
	color: #ccc;
	text-align: center;
	padding: 0px;
	outline: none;

	/* disable text selection */
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	/* prevent horizontal rubber-banding on ios */
	overscroll-behavior-x: none;
}

/* Hide all sections by default. */

body > div.landing,
body > div.header,
body > div.preload,
body > div.GLError,
body.landing > div#game,
body > .footer > .download,
button.fullscreen,
div#game > canvas {
	display: none;
}

/********** Begin WebGL Error State **************/
body.GLError > div.GLError {
	display: block;
}

body.GLError > div.GLError img {
	width: 100%;
}
/********** End WebGL Error State **************/

/********** Begin Landing **************/
body.landing {
	background-image: url("image/landing_background.png");
	background-repeat: no-repeat;
	background-position: top center;
}
body.landing div.landing {
	display: initial;
	width: 100%;
	height: 588px;
}

body.landing > .footer > .download {
	display: block;
}

img#playNow {
	margin-top: 70px;
	margin-bottom: 75px;
}
/********** End Landing **************/

/********** Begin Preload **************/
body.preload div.header {
	display: block;
}

body.preload div.preload {
	display: block;
	height: 640px;
}

body.preload img.background {
	height: 100%;
}

body.preload img.progressbar {
	position: absolute;
	top: 640px;
	left: calc(
		50% - 147.5px
	); /* Position middle by 50% of page width minus half of img width. */
}
/********** End   Preload **************/

/********** Begin Game **************/
body.game div.header {
	display: block;
}

body.game div#game {
	display: block;
	height: 640px;
	max-width: 1150px;
	min-width: 854px;
	margin: auto !important;
}

body.game button.fullscreen {
	display: inline-block;
}

body.game div#game > canvas {
	display: block;
	cursor: default;
	margin: auto;
	background: rgb(35, 31, 32);
}

/********** End   Game **************/

main {
	min-height: 588px;
}

.footer {
	margin-top: 10px;
}

.footer .download {
	margin-top: 5px;
}
.footer .download img {
	margin: 5px;
	height: 45px;
}

.header {
	position: relative;
	width: 800px;
	height: 80px;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
	margin-left: auto;
	margin-right: auto;
}

.hidden {
	display: none;
	visibility: hidden;
}

a {
	color: #fff;
	text-decoration: none;
	outline: none;
}

:focus {
	-moz-outline-style: none;
}

#best_deal_span {
	top: 35px;
	position: relative;
	left: 45px;
	color: black;
	font-weight: bold;
	font-family: sans-serif;
	font-size: small;
	width: 130px;
	float: left;
	text-align: center;
}
#best_deal_span2 {
	top: 35px;
	position: relative;
	left: 50px;
	color: black;
	font-weight: bold;
	font-family: sans-serif;
	font-size: small;
	float: left;
}
nav {
	margin: 0 auto;
	height: 30px;
	width: 650px;
	position: relative;
	opacity: 0;
	left: 25px;
}

@-moz-document url-prefix() {
	nav {
		bottom: 1px;
	}
}

.inactive {
	cursor: default !important;
	color: #999;
	background: #333;
	-webkit-transition: 0;
	-moz-transition: 0;
	-ms-transition: 0;
	-o-transition: 0;
}
.inactive:hover {
	background: #333;
	box-shadow: none;
	color: #999;
	text-shadow: none;
}
#logo {
	background-color: #250140;
	/*cursor: pointer;*/
	float: left;
	height: 108px;
	left: -215px;
	position: relative;
	top: -2px;
	transition: all 0.4s ease 0s;
	width: 100px;
	z-index: 1;
}
#logo_new {
	background-color: #250140;
	height: 80px;
	margin: auto;
	position: absolute;
	left: 0px;
	right: 0px;
	width: 309px;
	/*z-index: -1;*/
}
.discount_text {
	position: relative;
	top: 23px;
	color: white;
	width: 30px;
	text-align: center;
	white-space: normal;
	left: 30px;
	/* height: 20px; */
	float: left;
}
#gp_logo {
	width: 100px;
	height: 35px;
	margin-right: 10px;
}
#gp_logo img {
	color: #250140;
}
#ap_logo img {
	color: #250140;
}
#gp_logo a {
	color: #250140;
}
#ap_logo a {
	color: #250140;
}
#ap_logo {
	width: 105px;
	height: 31px;
	vertical-align: bottom;
	color: #250140;
}
#ipad_install_app {
	margin: 0 auto;
	top: 8px;
	left: -85px;
	cursor: pointer;
	width: 43px;
	height: 40px;
	float: right;
	position: relative;
	-webkit-border-radius: 200px;
	-moz-border-radius: 200px;
	border-radius: 200px;
	background-color: #250140;
	-webkit-transition: 0.4s ease;
	-moz-transition: 0.4s ease;
	-o-transition: 0.4s ease;
	-ms-transition: 0.4s ease;
	transition: 0.4s ease;
	display: block;
}
#ipad_install_app:hover {
	background-color: rgba(255, 255, 240, 0.2);
	box-shadow: rgba(255, 255, 240, 0.2) 0 0 200px 100px,
		rgba(255, 255, 240, 0.3) 0 0 40px, rgba(0, 0, 0, 0) 0 0 0px;
}

#logo_img {
	background-color: #250140;
	cursor: pointer;
	/*float: left;*/
	/*height: 108px;*/
	/*left: -215px;*/
	/*position: relative;*/
	/*top: -2px;*/
	/*transition: all 0.4s ease 0s;*/
	/*width: 100px;*/
	/*z-index: 1;*/
}
#disclaimer {
	position: relative;
	top: 20px;
	right: 0px;
	height: 15px;
	font-weight: bold;
	color: #fff;
}

textarea {
	text-align: center;
}
#one_click_buy_empty {
	position: relative;
	left: -200px;
	top: -70px;
	float: left;
	opacity: 100;
	width: 370px;
	height: 108px;
	color: white;
	font-weight: bold;
	font-family: sans-serif;
	display: block;
}
#one_click_buy_panel {
	position: relative;
	float: right;
	opacity: 100;
	width: 266px;
	height: 80px;
	color: white;
	font-weight: bold;
	font-family: sans-serif;
	display: block;
}

#ocb_desc {
	position: relative;
	color: white;
	font-weight: bold;
	font-family: sans-serif;
	width: 134px;
	background: transparent;
	border: none;
	overflow: hidden;
	resize: none;
	text-align: center;
	white-space: normal;
}
#mobile_collection_old {
	position: relative;
	width: 240px;
	height: 108px;
	float: left;
	opacity: 100;
	display: block;
	left: -190px;
	top: -48px;
	font-family: sans-serif;
}
#mobile_collection_new {
	float: left;
	width: 143px;
	height: 80px;
	font-family: sans-serif;
	opacity: 100;
	overflow: hidden;
	position: relative;
	text-align: right;
}
#mobile_collection {
	display: block;
	float: left;
	font-family: sans-serif;
	left: 100px;
	opacity: 100;
	overflow: hidden;
	position: relative;
	text-align: right;
	top: -58px;
	z-index: 2;
}
#mobile_collection table {
	margin-top: 5px;
	border-spacing: 0px;
	margin-bottom: 5px;
}

#send_to_phone_old {
	left: 0px;
	position: relative;
	/*right: 20px;*/
	text-align: right;
	width: 210px;
	overflow: hidden;
}
#send_to_phone {
	float: right;
	left: 50px;
	overflow: visible;
	position: relative;
	text-align: right;
	width: 180px;
	display: table;
}
#send_to_phone_new {
	float: left;
	height: 28px;
	overflow: visible;
	position: relative;
	text-align: right;
	width: 180px;
}
.play_on_phone {
	float: right;
	overflow: visible;
	position: relative;
	height: 28px;
	width: 180px;
}
#impmsg_Container {
	background-color: yellow;
	color: red;
	display: block;
	position: relative;
	right: 0px;
	width: 760px;
}

#wrapper {
	height: 680px;
}
/*
#allContent, #imageContent, #nav{
    margin-left:auto;
    margin-right: auto;
    height: 100%;
    width: 100%;
}
#allContent{
    position: relative;
}
*/
#imageContent {
	height: 680px;
	display: none;
}
#copyrightContainer {
	font-size: 10px;
	margin-top: 5px;
}
#copyrightContainer .fb-like {
	display: none;
}
#copyrightContainer span {
	cursor: pointer;
}
#copyrightContainer span:hover {
	text-decoration: underline;
}

.selectableContent {
	cursor: text;
	-webkit-touch-callout: text;
	-webkit-user-select: text;
	-khtml-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}

.selectableContent:hover {
	text-decoration: none;
}

.legal {
	position: relative;
	margin: 0 auto;
	top: -900px;
	width: 800px;
	z-index: 5555;
	background-color: #fff;
}

/* display: none; */

.hidden {
	display: none;
}

/****** Start Help Panel ******/
#help_wrapper {
	background: url("image/overlay.png") repeat 0 0;

	/*
      Position, top, width and height are set
      to make this cover the whole view
    */
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

iframe#helpshift_frame {
	width: 90%;
	height: 90%;
	margin-top: 5%;
}
/****** End Help Panel ******/


/******* from main.css.php ***********/
#return_to_classic {
	border-radius: 5px 5px 5px 5px;
	-moz-border-radius: 5px 5px 5px 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	border: 2px solid #ffffff;
	padding: 5px;
}

#bookmark {
	padding-top: 30px;
	border: none;
	color: white;
	float: left;
	font-weight: bold;
	font-family: sans-serif;
	z-index: 2;
}

#bookmark span {
	vertical-align: super;
	margin-left: 2px;
	/* Raise the span above the logo that is next to it. */
	position: absolute;
	z-index: 1;
	padding-top: 3px;
}

button.bookmark {
	background: url("image/bookmark.png") no-repeat;
	background-size: contain;
	width: 22px;
	border: none;
	height: 21px;
	cursor: pointer;
}

button.bookmark:hover {
	opacity: 70%;
}

button.fullscreen {
	background: url("image/btn_fullscreen.png") no-repeat;
	background-size: contain;
	width: 22px;
	border: none;
	height: 21px;
	cursor: pointer;
}

button.fullscreen:hover {
	background: url("image/btn_fullscreen_on.png") no-repeat;
}

#discount_message {
	color: #ffffff;
	font-family: sans-serif;
	font-weight: bold;
	height: 14px;
	position: relative;
	width: 134px;
	white-space: normal;
}
#discount_blast {
	color: #000000;
	float: left;
	font-family: sans-serif;
	font-weight: bold;
	height: 26px;
	position: relative;
	text-align: center;
	width: 226px;
	z-index: 3;
}

/* Default to hidden */
.webGLNotLoading {
	display: none;
}

/* Override hidden rule when webgl is broken */
body.GLError .webGLNotLoading {
	display: block;
}

#one_click_buy {
	background: url("image/coinoffer_panel.png") no-repeat scroll 0 0
		rgba(0, 0, 0, 0);
	color: #ffffff;
	float: left;
	font-family: sans-serif;
	font-weight: bold;
	height: 49px;
	opacity: 100;
	position: relative;
	width: 266px;
}
#one_click_short_buy_button {
	position: relative;
	background: url("image/btn_buy.png") no-repeat;
	background-size: contain;
	float: right;
	width: 51px;
	border: none;
	height: 24px;
	color: white;
	font-weight: bold;
	font-family: sans-serif;
	cursor: pointer;
}
#one_click_short_buy_button:hover {
	background: url("image/btn_buy_on.png") no-repeat;
	background-size: contain;
}
